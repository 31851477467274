import React from 'react'
import Grid from '@material-ui/core/Grid';

import Layout from "../components/layout"
import SEO from "../components/seo"


export default function DigitalPlays() {

    return(
        <Layout>
        <SEO title="Digital Plays" />
        <Grid container spacing={2} class="pastwork-body-wrapper">

        <div class="about-title-wrapper">
              <div class="productions-title">
                  Digital Plays
              </div>
          </div>
            <div class="digital-content-row pastwork-row">
                <div class="digital-pic-block digital-pic-1">
                </div>
                <div class="digital-content-block text-block">
                    <h2 class="glowist forty-font">The Year Without Summer</h2>
                    <div class="byline">
                        <p><strong>Written By:</strong> Neeley Gossett</p>
                    </div>

                    <p>In the near future, after a volcanic eruption causes major environmental changes, an American scientist travels to India to understand the disappearance of a subspecies of butterfly. On this journey, she faces personal choices that will determine her family's future.</p>
                </div>
            </div>

            <div class="digital-content-row pastwork-row">
                <div class="digital-pic-block digital-pic-2">
                </div>
                <div class="digital-content-block text-block">
                    <h2 class="glowist forty-font">The Atlanta PodPlays</h2>
                    <p>The Atlanta PodPlays is a series of walking audio stories that take place throughout Little Five Points. Rather than watching a live performance, you will use earbuds/headphones to listen to the plays on your smartphone as you walk through the neighborhood.</p>
                    <p>These PodPlays are written specifically for locations in Little Five Points and a narrator will direct you to walk to different places as you listen to the play. This is both an immersive and imminent experience, in which you will hear an audio play and physically explore your surroundings.</p>
                    <p>To begin, download the SoundCloud app on your smartphone and follow Found Stages Theatre. You can also search for “The Atlanta PodPlays” on SoundCloud. Hit play to stream the PodPlay of your choice, and follow the story from there!</p>
                    <div class="podplay-row">
                        <iframe class="i-frame digitalplays-iframe" title="The Atlanta Podplays: A Video Snapshot" src="https://www.youtube.com/embed/tyPcPEaLWo8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        <iframe class="i-frame digitalplays-iframe" title="The Atlanta Podplays on Soundcloud" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/259089660&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                    </div>
                </div>           
            </div>
        </Grid>
        </Layout>
    )
}
